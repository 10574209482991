import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Img from "gatsby-image";
import Carousel from 'react-bootstrap/Carousel';

const Projects = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  return (
    <Layout bodyClass="page-project">
      <SEO title={title} />

      <div className="intro mt-5 mt-lg-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{title}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <Carousel>
          {data.allFile.nodes.map(image => (
            <Carousel.Item key={image.id}>
              <Img key={image.id} fluid={image.childImageSharp.fluid} />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="content mt-2" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!, $images: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
      }
      html
    }
    allFile(filter: {relativeDirectory: { eq: $images }}) {
      nodes {
        id
        childImageSharp {
          fluid(quality : 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
export default Projects;
